import { Injectable } from '@angular/core';
import { Invite } from '../../models/invite.model';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class InviteService {

  private invitesCollection: AngularFirestoreCollection<Invite>;
  private invitesHistoryCollection: AngularFirestoreCollection<Invite>;

  private invites: Observable<Invite[]>;
  private invitesHistory: Observable<Invite[]>;

  constructor(
    public db: AngularFirestore
  ) {
    this.invitesCollection = db.collection<Invite>('invites');
    this.invitesHistoryCollection = db.collection<Invite>('invites_history');

    this.invites = this.invitesCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          return { id, ...data }
        })
      })
    );

    this.invitesHistory = this.invitesHistoryCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          return { id, ...data }
        })
      })
    );
  }


  getInvite() {
    this.invitesCollection = this.db.collection<Invite>('invites');

    this.invites = this.invitesCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          return { id, ...data }
        })
      })
    );
    return this.invites;
  }

  getInvitesHistory() {
    this.invitesHistoryCollection = this.db.collection<Invite>('invites_history');

    this.invitesHistory = this.invitesHistoryCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          return { id, ...data }
        })
      })
    );
    return this.invitesHistory;
  }


  addInvite(userId: any, invite: any, forUpdate: Boolean) {
    // debugger;
    var demo;
    if(!forUpdate) {
      let refid = this.db.createId();
      //console.log(refid);
      demo = {[refid]: invite};
    } else {
      demo = invite;
    }

    /*let refid = this.db.createId();
    console.log(refid);
    // debugger;
    var demo = {[refid]: invite};*/
    return this.invitesCollection.doc(userId).set(demo);
  }

  deleteInvite(userId: any, objectKey: any, fullDelete: Boolean, invite: any){
    var demo = {[objectKey]: invite};
    if(fullDelete) {
      return this.invitesCollection.doc(userId).delete();
    }
    return this.invitesCollection.doc(userId).set(demo);
  }

  updateInvited(usrId, invit){
    return this.invitesCollection.doc(usrId).update(invit);
  }

  addInviteToo(userId: any, invitePos: any, invite: Invite) {
    let ref = this.db.createId();
    return this.invitesCollection.doc(userId).collection(ref).doc(invitePos).set(invite);
  }

  getInviteFriend(userid){
    return this.invitesCollection.doc(userid).valueChanges();
  }

  afterRemoveRecord(userId){
    return this.invitesCollection.doc(userId).delete();
  }


  /*addInvitesHistory(userId: any, invite: any){
    // debugger;
    var demo;
    let refid = this.db.createId();
    demo = {[refid]: invite};
    return this.invitesHistoryCollection.doc(userId).set(invite);
  }*/

  addInvitesHistory(userId, invite: any){
    return this.invitesHistoryCollection.doc(userId).set(invite);
  }

  updateInvitesHistory(userId, invite: any){
    // debugger;
    return this.invitesHistoryCollection.doc(userId).update(invite);
  }

  getInvitesHistoryDate(userId){
    return this.invitesHistoryCollection.doc(userId).valueChanges();
  }
}