import { Component, OnInit } from '@angular/core';
import { LoginService } from '../../../services/login/login.service';
import { Router } from '@angular/router';
import { LoadingController } from '@ionic/angular';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { DEV_ADMIN_SERVER_URL } from '../../../../environments/environment';


@Component({
  selector: 'app-admin',
  templateUrl: './admin.page.html',
  styleUrls: ['./admin.page.scss'],
})
export class AdminPage implements OnInit {
  users: any;
  applicants: any;
  companys: any;
  constructor(
    private loginService: LoginService,
    private router: Router,
    public loadingController: LoadingController,
    private http: HttpClient,
    private translate: TranslateService
  ) {

  }

  async ngOnInit() {
    const loading = await this.loadingController.create({
      message: this.translate.instant('LOADING.message')
    });
    loading.present();
    this.loginService.getUsers().subscribe(users => {
      loading.dismiss();
      this.users = users;
      this.applicants = this.users.filter(function (user) {
        return user.role === '3';
      });
      this.companys = this.users.filter(function (company) {
        return company.role === '11';
      });
    });
  }

  deleteUser(uid) {
    const headers = new HttpHeaders();
    headers.append('Content-type', 'application/json');
    this.loginService.deleteUser(uid);
    //TODO: this admin is no more in use should remove this
    // this.http.get(`https://appbaseapp.herokuapp.com/api/${uid}`, { headers: headers })
    //   .subscribe(res => { console.log(res); });

    this.http.get(`${DEV_ADMIN_SERVER_URL}/api/users/${uid}`, { headers: headers })
      .subscribe(res => { console.log(res); });
  }

}
