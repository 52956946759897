import { Injectable } from '@angular/core';
import * as firebase from 'firebase';
import 'firebase/storage';

@Injectable({
  providedIn: 'root'
})
export class ImageService {

  constructor() { }

  encodeImageUri(imageUri, callback) {
    var c = document.createElement('canvas');
    var ctx = c.getContext("2d");
    var img = new Image();
    img.onload = function () {
      var aux: any = this;
      c.width = aux.width;
      c.height = aux.height;
      ctx.drawImage(img, 0, 0);
      var dataURL = c.toDataURL("image/jpeg");
      callback(dataURL);
    };
    img.src = imageUri;
  };

  uploadImage(imageURI, imagename = 'imageName') {
    return new Promise<any>((resolve, reject) => {
      let storageRef = firebase.storage().ref();
      let imageRef = storageRef.child('image').child(imagename);
      this.encodeImageUri(imageURI, function (image64) {
        imageRef.putString(image64, 'data_url')
          .then(snapshot => {
            snapshot.ref.getDownloadURL().then(function (url) {
              resolve(url);
            });
          }, err => {
            reject(err);
          });
      });
    });
  }

  uploadBackgroundImage(imageURI, imagename = 'imageName') {
    return new Promise<any>((resolve, reject) => {
      let storageRef = firebase.storage().ref();
      let imageRef = storageRef.child('background-photos').child(imagename);
      this.encodeImageUri(imageURI, function (image64) {
        imageRef.putString(image64, 'data_url')
          .then(snapshot => {
            snapshot.ref.getDownloadURL().then(function (url) {
              resolve(url);
            });
          }, err => {
            reject(err);
          });
      });
    });
  }


  uploadImageForLogo(imageURI, imagename = 'imageName') {
    return new Promise<any>((resolve, reject) => {
      let storageRef = firebase.storage().ref();
      let imageRef = storageRef.child('logo').child(imagename);
      this.encodeImageUri(imageURI, function (image64) {
        imageRef.putString(image64, 'data_url')
          .then(snapshot => {
            snapshot.ref.getDownloadURL().then(function (url) {
              resolve(url);
            });
          }, err => {
            reject(err);
          });
      });
    });
  }


  uploadImageForFeedPost(imageURI, imagename = 'imageName') {
    return new Promise<any>((resolve, reject) => {
      let storageRef = firebase.storage().ref();
      let imageRef = storageRef.child('feed-post').child(imagename);
      this.encodeImageUri(imageURI, function (image64) {
        imageRef.putString(image64, 'data_url')
          .then(snapshot => {
            snapshot.ref.getDownloadURL().then(function (url) {
              resolve(url);
            });
          }, err => {
            reject(err);
          });
      });
    });
  }
}
