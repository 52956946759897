import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { News } from '../../models/news.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LoginService} from '../../services/login/login.service';
import * as firebase from 'firebase';

interface QueryConfig {
  path: string; //  path to collection
  field: string; // field to orderBy
  limit: number; // limit per query
  reverse: boolean; // reverse order?
  prepend: boolean; // prepend to source?
}

@Injectable({
  providedIn: 'root'
})
export class NewsService {

  private query: QueryConfig = {
    field: 'date',
    limit: 50,
    path: 'news',
    prepend: true,
    reverse: true
  };

  private newsCollection: AngularFirestoreCollection<News>;
  private news: Observable<News[]>;

  constructor(private db: AngularFirestore) {
    this.newsCollection = db.collection<News>('news');
  }
  getNews() {
    const first = this.db.collection(this.query.path, ref => {
      return ref.orderBy(this.query.field, this.query.reverse ? 'desc' : 'asc')
                .limit(this.query.limit);
      });
      return first.snapshotChanges().pipe(
        map(actions => {
          return actions.map(a => {
            const data = a.payload.doc.data();
            const id = a.payload.doc.id;
            return { id, ...data };
          });
        })
    );
  }

  getnewsById(id) {
    return this.newsCollection.doc<News>(id).valueChanges();
  }

  addNews(mews: News) {
    return this.newsCollection.add(mews); // MAGIC LINE
  }

  updateNews(news: News, id: string) {
    return this.newsCollection.doc(id).update(news);
  }

  removeNews(id) {
    return this.newsCollection.doc(id).delete();
  }

  getWithLimit( starting: any)  {
    const first = this.db.collection(this.query.path, ref => {
      return ref
              .orderBy(this.query.field)
              .startAfter(starting)
              .limit(this.query.limit);
    });

    return first.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          return { id, ...data };
        });
      })
    );
  }

  getAllNews() {
    const first = this.db.collection(this.query.path);
    return first.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          return { id, ...data };
        });
      })
    );
  }
}
